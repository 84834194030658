
import { CheckIcon } from '@heroicons/vue/solid';
import { mixins, Options } from 'vue-class-component';
import OAuthMultiWalletMultiNetworkMixin from '@/mixins/oAuthMultiWalletMultiNetwork';

@Options({
  components: {
    CheckIcon
  }
})
export default class OAuthMultiConnectMultiNetwork extends mixins(
  OAuthMultiWalletMultiNetworkMixin
) {
  showConnectOptions = false;
  showConnectedMenu = false;

  toggleConnectedMenu(): void {
    if (!this.wrongChain) {
      this.showConnectedMenu = !this.showConnectedMenu;
    }
  }

  closeConnectedMenu(): void {
    this.showConnectedMenu = false;
  }

  setShowConnectOptions(): void {
    this.showConnectOptions = !this.showConnectOptions;
  }

  closeConnectOptions(): void {
    this.showConnectOptions = false;
  }

  async disconnectAndClose(): Promise<void> {
    this.closeConnectOptions();
    await this.disconnectWallet();
  }
}
