
import { mixins, Options } from 'vue-class-component';
import { MConnectProvidePropsMixin } from '@/exports/MConnectProps';
import Connect from '@/lib-components/Connect.vue';
import MinimalConnect from '@/lib-components/MinimalConnect.vue';
import MultiConnect from '@/lib-components/MultiConnect.vue';
import OAuthConnect from '@/lib-components/OAuthConnect.vue';
import OAuthMultiConnect from '@/lib-components/OAuthMultiConnect.vue';
import OAuthMultiConnectMultiNetwork from '@/lib-components/OAuthMultiConnectMultiNetwork.vue';

@Options({
  components: {
    Connect,
    MinimalConnect,
    MultiConnect,
    OAuthConnect,
    OAuthMultiConnect,
    OAuthMultiConnectMultiNetwork
  }
})
export default class MConnect extends mixins(MConnectProvidePropsMixin) {}
