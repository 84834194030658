import useManifoldCSS from '@manifoldxyz/css-reset';
import './scss/walletConnect.css';
import { DATA_WIDGET_VALUE } from '@/common/constants';
import { getAllChildrenAndRoot, parseJSONProp } from '@/common/functions';
import MConnect from '@/exports/MConnect.vue';
import { MConnectOptionsProps, MConnectOptionsPropsType } from '@/exports/MConnectProps';
import { renderComponentWithApp } from '../build/mount';
import { name as packageName } from '../package.json';

// track app-component Vue instances for proper destroying later
const renderedComponents = new Map();
let widgetAttributeChangeObserver: MutationObserver;
let bodyChangeObserver: MutationObserver;

/* Render and Destroy a Widget */

/*
 * Replaces the el with a rendered Vue component (as its own Vue app)
 * then monitors that div for any attribute changes, that way we know when
 * to automatically destroy + rerender the widget with new props.
 * This also handles parsing the data-attrs into typechecked Vue props.
 */
const replaceWithWidget = (el: HTMLElement) => {
  // grab the DOM element's data attributes to use as propsData
  const data = el.dataset;

  // parse all relevant data-attrs and typecast them into props
  const propsKeys = Object.keys(MConnectOptionsProps);
  const propsData: Record<string, unknown> = {};
  propsKeys.forEach((key: string): void => {
    const propDefinition = MConnectOptionsProps[key as MConnectOptionsPropsType];

    const dataValue = data[key];
    /*
     * since dataset is always a string array, we need to do some
     * typecasting and inferencing here to set propsData[key] properly
     */
    if (!dataValue) {
      propsData[key] = propDefinition.default;
      return;
    }

    if (propDefinition.type === String) {
      propsData[key] = data[key];
      return;
    }

    if (propDefinition.type === Number) {
      propsData[key] = JSON.parse(dataValue as string);
      return;
    }

    if (propDefinition.type === Boolean) {
      propsData[key] = JSON.parse(dataValue as string);
      return;
    }

    if (propDefinition.type === Array) {
      // We treat Array as csv string, so we need to split it into an array
      const splitData = dataValue.split(',');
      const parsedSplitData = splitData
        .map((data) => {
          if (propDefinition.elementType === Number) {
            return JSON.parse(data as string);
          }

          if (propDefinition.elementType === Boolean) {
            return JSON.parse(data as string);
          }
          return data.trim();
        })
        .filter((value) => !!value);
      propsData[key] = parsedSplitData;
      return;
    }

    propsData[key] = parseJSONProp(dataValue, key, propDefinition.type);
  });

  // render the component
  const renderedComponent = renderComponentWithApp({
    el: el,
    component: MConnect,
    props: propsData
  });
  renderedComponents.set(el, renderedComponent);

  // observe any attribute changes and rerender accordingly
  const config = {
    attributes: true,
    childList: false,
    subtree: false
  };
  widgetAttributeChangeObserver.observe(el, config);
};

/*
 * Checks if the el has a corresponding rendered Vue component in memory.
 * If it does, we unmount the Vue component and destroy its data in memory.
 */
const destroyPotentialWidget = (el: HTMLElement) => {
  const renderedComponentRef = renderedComponents.get(el);
  if (renderedComponentRef) {
    // unmount and destroy the pre-existing Vue app-component for memory's sake
    renderedComponentRef();
    renderedComponents.delete(el);
  }
};

/* Mutation Handlers */

/*
 * When a previously rendered widget has an attribute changed we need to
 * destroy the potential widget that was already rendered in that div
 * and then render a brand new widget that uses the new data as props in
 * its place.
 */
const handleWidgetAttributeChange = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName !== 'data-v-app') {
      // destroy pre-existing app-component before replacing it with new one
      destroyPotentialWidget(mutation.target as HTMLElement);
      replaceWithWidget(mutation.target as HTMLElement);
    }
  });
};

/*
 * Whenever the body changes we want to see:
 *
 * 1. If a div with data-widget was added to the DOM
 * 2. If a div with data-widget was removed from the DOM
 * 3. If a div dynamically had the data-widget attr added
 *
 * If any are true, we want to render or destroy the widget in that div.
 */
const handleBodyChanges = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    // dynamically added a node that may have a div with our data-widget attribute inside
    mutation.addedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (htmlEl?.dataset?.widget === DATA_WIDGET_VALUE) {
        replaceWithWidget(htmlEl);
        return;
      }

      const children = getAllChildrenAndRoot(htmlEl);
      children.pop(); // last element is root el which we've already checked above

      children.forEach((child: Element) => {
        if ((child as HTMLElement)?.dataset?.widget === DATA_WIDGET_VALUE) {
          replaceWithWidget(child as HTMLElement);
        }
      });
    });

    // dynamically removed a node that had our widget rendered into it
    mutation.removedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (htmlEl?.dataset?.widget === DATA_WIDGET_VALUE) {
        destroyPotentialWidget(htmlEl);
        return;
      }

      const children = getAllChildrenAndRoot(htmlEl);
      children.forEach((child: Element) => {
        if ((child as HTMLElement)?.dataset?.widget === DATA_WIDGET_VALUE) {
          destroyPotentialWidget(child as HTMLElement);
        }
      });
    });

    // dynamically added the data-widget attribute
    if (mutation.type === 'attributes' && mutation.attributeName === 'data-widget') {
      const htmlEl = mutation.target as HTMLElement;
      if (htmlEl?.dataset?.widget === DATA_WIDGET_VALUE) {
        replaceWithWidget(htmlEl);
      }
    }
  });
};

/* Main Script */

const main = (): void => {
  // MutationObserver gets reused by every rendered component
  widgetAttributeChangeObserver = new window.MutationObserver(handleWidgetAttributeChange);

  // manually sweep the DOM for pre-existing widget divs that need replacing
  const elements = document.querySelectorAll(`[data-widget="${DATA_WIDGET_VALUE}"]`);
  elements.forEach((el: Element) => {
    replaceWithWidget(el as HTMLElement);
  });

  // Listen for new widget divs being added/removed to the body of the DOM.
  // Also listen for divs dynamically adding the data-widget attribute.
  const bodyNode = document.querySelector('body');
  if (bodyNode && !bodyChangeObserver) {
    const config = {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['data-widget']
    };

    bodyChangeObserver = new window.MutationObserver(handleBodyChanges);
    bodyChangeObserver.observe(bodyNode, config);
  }
};

if (window) {
  useManifoldCSS({ reset: 'all', styles: 'vars' }, packageName);

  // Checks if `load` has already fired so we dont wait indefinitely to call main()
  // ref: https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/loadEventEnd
  const alreadyLoaded = performance
    .getEntriesByType('navigation')
    .every((e) => (e as PerformanceNavigationTiming).loadEventEnd);

  if (alreadyLoaded) {
    // the `load` event already fired so we're ready to do main() instantly
    main();
  } else {
    // we only want to call main() after the page `load` fires
    window.addEventListener('load', main);
  }
}
