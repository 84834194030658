import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bad-configuration"
}
const _hoisted_2 = { type: "button" }
const _hoisted_3 = {
  key: 1,
  class: "m-connected-container"
}
const _hoisted_4 = {
  key: 0,
  class: "m-connection-avatar"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "m-connection-balance"
}
const _hoisted_7 = {
  key: 3,
  class: "m-connection-wrong-network"
}
const _hoisted_8 = { class: "m-connection-wallet" }
const _hoisted_9 = {
  key: 2,
  class: "m-connection-dropdown"
}
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "m-connection",
    class: _normalizeClass({ connected: _ctx.oauthToken, menu: _ctx.showDisconnectMenu })
  }, [
    (!_ctx.walletAvailable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "m-connection-install-wallet",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMetamaskLink && _ctx.openMetamaskLink(...args)))
        }, " Install Wallet "))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.badConfiguration)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.badConfiguration), 1)
              ]))
            : (_ctx.oauthToken || (_ctx.delayAuth && _ctx.walletConnected))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_ctx.wrongChain && _ctx.enableNetworkSwitching)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "m-connection-switch-network",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.switchNetwork && _ctx.switchNetwork(...args)))
                      }, " Switch network "))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        type: "button",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDisconnectMenu = !_ctx.showDisconnectMenu))
                      }, [
                        (_ctx.avatar)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                              _createElementVNode("img", {
                                class: "m-connection-avatar-img",
                                src: _ctx.avatar
                              }, null, 8, _hoisted_5)
                            ]))
                          : _createCommentVNode("", true),
                        (!_ctx.wrongChain && _ctx.showBalance)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.walletBalance) + " ETH ", 1))
                          : _createCommentVNode("", true),
                        (!_ctx.wrongChain && _ctx.showChain && _ctx.chainInfo)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 2,
                              class: "m-connection-chain-id",
                              style: _normalizeStyle({ 'background-color': _ctx.chainInfo.color.toString() })
                            }, null, 4))
                          : _createCommentVNode("", true),
                        (_ctx.wrongChain)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.walletENS ? _ctx.walletENS : _ctx.walletAddressShort), 1)
                      ])),
                  (_ctx.showDisconnectMenu)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _renderSlot(_ctx.$slots, "dropdown"),
                        _createElementVNode("button", {
                          class: "m-connection-disconnect-wallet",
                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.disconnectAndClose && _ctx.disconnectAndClose(...args)))
                        }, [
                          _createElementVNode("span", {
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showDisconnectMenu = !_ctx.showDisconnectMenu))
                          }, " Disconnect ")
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  type: "button",
                  class: _normalizeClass('m-connection-connect-wallet' + (_ctx.wrongChain ? ' m-connection-wrong-network' : '')),
                  disabled: _ctx.isLoading,
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.connectDefaultBrowserWallet && _ctx.connectDefaultBrowserWallet(...args)))
                }, [
                  (_ctx.connectWalletImage)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        id: "connect-wallet-image",
                        src: _ctx.connectWalletImage
                      }, null, 8, _hoisted_11))
                    : (_ctx.wrongChain)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Wrong Network "))
                      : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.buttonText), 1))
                ], 10, _hoisted_10))
        ], 64))
  ], 2))
}