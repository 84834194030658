
import { mixins } from 'vue-class-component';
import OAuthMultiWalletMixin from '@/mixins/oAuthMultiWallet';

export default class OAuthMultiConnect extends mixins(OAuthMultiWalletMixin) {
  showConnectOptions = false;

  setShowConnectOptions(): void {
    this.showConnectOptions = !this.showConnectOptions;
  }

  closeConnectOptions(): void {
    this.showConnectOptions = false;
  }

  async disconnectAndClose(): Promise<void> {
    this.closeConnectOptions();
    await this.disconnectWallet();
  }
}
