import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-connect manifold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OAuthConnect = _resolveComponent("OAuthConnect")!
  const _component_OAuthMultiConnect = _resolveComponent("OAuthMultiConnect")!
  const _component_OAuthMultiConnectMultiNetwork = _resolveComponent("OAuthMultiConnectMultiNetwork")!
  const _component_MultiConnect = _resolveComponent("MultiConnect")!
  const _component_MinimalConnect = _resolveComponent("MinimalConnect")!
  const _component_Connect = _resolveComponent("Connect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.detectApp || (_ctx.clientId && _ctx.appName))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!_ctx.multi)
            ? (_openBlock(), _createBlock(_component_OAuthConnect, { key: 0 }))
            : (_ctx.network && _ctx.network.length + _ctx.optionalNetwork.length === 1)
              ? (_openBlock(), _createBlock(_component_OAuthMultiConnect, { key: 1 }))
              : (_openBlock(), _createBlock(_component_OAuthMultiConnectMultiNetwork, { key: 2 }))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.multi)
            ? (_openBlock(), _createBlock(_component_MultiConnect, { key: 0 }))
            : (_ctx.minimal)
              ? (_openBlock(), _createBlock(_component_MinimalConnect, { key: 1 }))
              : (_openBlock(), _createBlock(_component_Connect, { key: 2 }))
        ], 64))
  ]))
}