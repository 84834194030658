import { mixins } from 'vue-class-component';
import { EthereumProvider } from '@manifoldxyz/frontend-provider-core';
import ManifoldDataClient from '@manifoldxyz/oauth-data-client';
import { AUTHENTICATED, UNAUTHENTICATED } from '@/common/constants';
import web3TransactionErrorHandling, {
  TransactionError,
  TransactionErrors
} from '@/common/web3TransactionErrorHandling';
import { DelayAuth } from '@/exports/MConnectProps';
import WalletMixin from '@/mixins/wallet';

export default class OAuthWalletMixin extends mixins(WalletMixin) {
  oauthToken: string | null | undefined = null;
  signatureAddress: string | null | undefined = null;

  /**
   * Overrides _disconnect found in WalletMixin
   */
  _disconnect(skipProviderDisconnect = false, force = false): void {
    // @dev: save for later use by disconnectOAuthAndEthProvider()
    const walletAddressFull = this.walletAddressFull;
    const oauthToken = this.oauthToken;
    // @dev: _disconnectBase(true, force) to disconnect EthereumProvider ourselves with this.strictAuth later
    this._disconnectBase(true, force);
    this._disconnectOAuthAndEthProvider(skipProviderDisconnect, walletAddressFull, oauthToken);
  }

  _disconnectOAuthAndEthProvider(
    skipProviderDisconnect = false,
    walletAddressFull: string | undefined | null,
    oauthToken: string | undefined | null
  ): void {
    // Previously had a wallet and was authed, need to reset
    if (oauthToken && walletAddressFull) {
      // @dev: base disconnect in WalletMixin has already reset everything else
      this.oauthToken = undefined;
      // so that client can also listen for auth state change
      window.dispatchEvent(
        new CustomEvent(UNAUTHENTICATED, {
          detail: {
            address: this.walletAddressFull
          }
        })
      );
    }

    // now actually do the request disconnect from the provider
    if (!skipProviderDisconnect) {
      // this will trigger onAddressChanged which handles state etc
      EthereumProvider.disconnect(this.strictAuth);
    }
  }

  /**
   * Authentication helper
   *
   * First it updates all chainInfo.
   * Then it updates everything related to the wallet adddress and ens name
   * if possible. Finally it stores the wallet address as "connectAddress"
   * inside of local storage. If there was an issue or the address is now
   * undefined/null, we clear all wallet related values and clear localStorage
   * of the "connectedAddress" itme.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async _authenticate(force = false): Promise<void> {
    this.badConfiguration = null;

    // switching addresses without an oauth causes delayAuth to be false
    const continueToDelayAuth =
      this.delayAuth === DelayAuth.always ||
      (this.delayAuth === DelayAuth.true && !this.oauthToken);

    this.updateChainInfo();
    const address = EthereumProvider.selectedAddress();
    const ens = EthereumProvider.selectedENSName();
    // Only change if address has changed OR it has been zero'd out
    if (
      force ||
      address !== this.walletAddressFull ||
      ens !== this.walletENS ||
      address === undefined
    ) {
      // grab previously connected method so we can recover later
      const connectMethod = localStorage.getItem('connectMethod');
      // Reset current state via disconnect (no need to disconnect the provider or force disconnect)
      this._disconnect(true);

      this.walletAddressFull = address;
      this.walletENS = ens;

      if (address) {
        try {
          this.signatureAddress = this.walletAddressFull;

          let appName, clientId, grantType;
          if (this.detectedApp) {
            appName = this.detectedApp.app;
            clientId = this.detectedApp.clientId;
            grantType = this.detectedApp.grantType;
          } else {
            appName = this.appName;
            clientId = this.clientId;
            grantType = this.grantType;
          }

          // check if they want an oauth token and data client
          if (clientId && appName) {
            this.oauthToken = await EthereumProvider.getOAuth({
              grantType: grantType,
              appName: appName,
              clientId: clientId,
              strictAuth: this.strictAuth,
              delayAuth: continueToDelayAuth,
              message: this.message ? this.message : ''
            });

            if (this.oauthToken) {
              const dataClient = new ManifoldDataClient({
                token: this.oauthToken || '',
                network: this.getSupportedNetworks().length === 1 ? this.network[0] : undefined // set undefined for multi-networks app
              });

              // so that client can query the current auth state
              window.manifold = {
                isAuthenticated: true,
                address: this.signatureAddress,
                accessToken: this.oauthToken,
                dataClient
              };
              // so that client can also listen for auth state change and use data client
              window.dispatchEvent(
                new CustomEvent(AUTHENTICATED, {
                  detail: {
                    address: this.signatureAddress,
                    accessToken: this.oauthToken,
                    client: dataClient
                  }
                })
              );
            }
          }

          // update UX variables
          const addressLength = address.length;
          const newShortenedAddress =
            address.slice(0, 6) + '...' + address.slice(addressLength - 4, addressLength);
          this.walletAddressShort = newShortenedAddress;
          this.walletConnected = true;

          // update LS, as disconnect() will have cleared it
          if (connectMethod) {
            localStorage.setItem('connectMethod', connectMethod);
          }
          localStorage.setItem('connectedAddress', address);
        } catch (error) {
          if (!this.badConfiguration) {
            const transactionErrors = web3TransactionErrorHandling(error as TransactionError);
            switch (transactionErrors) {
              case TransactionErrors.REJECTED: {
                // Force disconnect
                this._disconnect(false, true);
                this.oauthToken = undefined;
                break;
              }
              case TransactionErrors.LEDGER_ERROR: {
                // Force disconnect
                this._disconnect(false, true);
                this.oauthToken = undefined;
                break;
              }
              case TransactionErrors.PENDING: {
                alert(`Please open your wallet to continue.`);
                break;
              }
              default: {
                // Force disconnect
                this._disconnect(false, true);
                this.oauthToken = undefined;
                // removing the alert for now till we figure out why the error message for transaction reject is weird
                // alert('There was an issue with that wallet connection');
                break;
              }
            }
          }
        }
      } else {
        // No address, no state
        this._disconnect(true);
      }
      this.isLoading = false;
    }
  }
}
