import { mixins } from 'vue-class-component';
import { EthereumProvider } from '@manifoldxyz/frontend-provider-core';
import OAuthMultiWalletMixin from '@/mixins/oAuthMultiWallet';

export default class OAuthMultiWalletMultiNetworkMixin extends mixins(OAuthMultiWalletMixin) {
  /**
   * Switches wallet to the default chain
   */
  async switchNetwork(): Promise<void> {
    const supportedNetworks = this.getSupportedNetworks();
    // Possible no default networks defined
    if (supportedNetworks.length === 0) return;
    const currentChainId = EthereumProvider.chainId();
    if (currentChainId && this.getSupportedNetworks().includes(currentChainId)) {
      // No need to swtich chains because we are on a supported chain
    } else {
      // Switch to the first supported chain
      await EthereumProvider.switchToChain(supportedNetworks[0]);
    }
  }
}
