import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bad-configuration"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "m-connection-wrong-network"
}
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "m-connection",
    class: _normalizeClass({ connected: _ctx.walletConnected })
  }, [
    (!_ctx.walletAvailable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMetamaskLink && _ctx.openMetamaskLink(...args)))
        }, "Install Wallet"))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.badConfiguration)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("button", null, _toDisplayString(_ctx.badConfiguration), 1)
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.walletConnected)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_ctx.wrongChain && _ctx.enableNetworkSwitching)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "m-connection-switch-network",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.switchNetwork && _ctx.switchNetwork(...args)))
                          }, " Switch network "))
                        : (_ctx.wrongChain)
                          ? (_openBlock(), _createElementBlock("button", _hoisted_3, " Wrong Network "))
                          : (_openBlock(), _createElementBlock("button", {
                              key: 2,
                              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.disconnectWallet && _ctx.disconnectWallet(...args)))
                            }, " Disconnect "))
                    ]))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      disabled: _ctx.isLoading,
                      class: _normalizeClass(_ctx.wrongChain ? 'm-connection-wrong-network' : ''),
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.connectDefaultBrowserWallet && _ctx.connectDefaultBrowserWallet(...args)))
                    }, [
                      (!_ctx.wrongChain)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(" Wrong Network ")
                          ], 64))
                    ], 10, _hoisted_4))
              ], 64))
        ], 64))
  ], 2))
}