import { mixins } from 'vue-class-component';
import OAuthWalletMixin from '@/mixins/oAuthWallet';
import MultiWalletMixin from './multiWallet';

export default class OAuthMultiWalletMixin extends mixins(
  MultiWalletMixin, // @dev: MultiWalletMixin first to get all multi wallet methods
  OAuthWalletMixin // @dev: OAuthWalletMixin second to inject authentication into the flow
) {
  _disconnect(skipProviderDisconnect = false, force = false): void {
    // @dev: save for later with oauth disconnection
    const walletAddressFull = this.walletAddressFull;
    const oauthToken = this.oauthToken;
    this._disconnectMultiWallet(skipProviderDisconnect);
    // @dev: _disconnectBase(true, force) to disconnect EthereumProvider ourselves with this.strictAuth later
    this._disconnectBase(true, force);
    this._disconnectOAuthAndEthProvider(skipProviderDisconnect, walletAddressFull, oauthToken);
  }

  /**
   * Overrides the _automaticallyConnect method to use the core method from MultiWalletMixin
   */
  async _automaticallyReconnect(): Promise<void> {
    this._automaticallyConnectMultiWallet();
  }
}
