import { Web3Provider } from '@ethersproject/providers';
import { formatEther } from '@ethersproject/units';
import { markRaw } from 'vue';
import { mixins, Options } from 'vue-class-component';
import { EthereumProvider } from '@manifoldxyz/frontend-provider-core';
import {
  ADDRESS_CHANGED,
  CHAIN_CHANGED,
  EthereumNetwork,
  PROVIDER_CHANGED
} from '@manifoldxyz/frontend-provider-types';
import { detectProvider } from '@manifoldxyz/studio-app-sdk';
import { AppDetails, getDetails } from '@/api/auto-detect';
import {
  AbstractProvider,
  ETHEREUM_NETWORK_COLORS,
  InjectedProvider,
  REAUTHENTICATE
} from '@/common/constants';
import web3TransactionErrorHandling, {
  TransactionError,
  TransactionErrors
} from '@/common/web3TransactionErrorHandling';
import { MConnectInjectPropsMixin } from '@/exports/MConnectProps';

export enum BrowserWallets {
  CoinbaseWallet = 'Coinbase Wallet',
  MetaMask = 'MetaMask',
  Bitski = 'Bitski',
  Brave = 'Brave',
  LedgerConnect = 'Ledger Connect',
  Rainbow = 'Rainbow Wallet'
}

export interface BrowserWalletConfig {
  name: `${BrowserWallets}`;
  network: number | undefined;
  provider?: InjectedProvider;
  logo?: string;
}

@Options({
  watch: {
    walletAddressFull: async function () {
      await this.updateBalance();
    }
  }
})
export default class WalletMixin extends mixins(MConnectInjectPropsMixin) {
  // variables that are not injected and defined only here
  badConfiguration: string | null | undefined = null;
  providerAvailable = false;
  walletAddressFull: string | undefined = '';
  walletAddressShort: string | undefined = '';
  walletENS: string | undefined = '';
  walletBalance: string | undefined = '';
  walletConnected = false;
  walletsHidden: Set<string> = new Set();
  wrongChain = false;
  isLoading = false;
  chainInfo: {
    name: keyof typeof EthereumNetwork;
    color: typeof ETHEREUM_NETWORK_COLORS;
  } | null = null;
  browserWallets: BrowserWalletConfig[] = [];
  detectedApp: AppDetails | undefined = undefined;

  get walletAvailable(): boolean {
    return !!(window && window.ethereum);
  }

  get buttonText(): string {
    return this.isLoading
      ? 'Logging in...'
      : this.overrideConnectText
      ? this.overrideConnectText
      : 'Connect Wallet';
  }

  /**
   * @returns the default browser wallet's configuration, if available.
   */
  get defaultBrowserWalletConfig(): BrowserWalletConfig | undefined {
    const abstractProvider = EthereumProvider.browserProvider() as AbstractProvider;
    if (abstractProvider) {
      // return root level browser provider if available
      return this.getBrowserWalletConfig(abstractProvider.provider, EthereumProvider.chainId());
    }
  }

  /**
   * @dev Computes the BrowserWalletConfig for every available provider given to
   *      us by either window.etherum itself, or by the window.ethereum.providers
   *      array. Also ensures that when metamask is available, it is the first wallet option.
   */
  async computeAllBrowserWalletConfigs(): Promise<void> {
    const wallets: BrowserWalletConfig[] = [];
    const walletNames: Set<string> = new Set();

    const hasInjectedBrowserProvider = !!EthereumProvider.browserProvider();
    if (hasInjectedBrowserProvider) {
      const windowEthereum = window.ethereum as InjectedProvider;
      if (windowEthereum?.providers) {
        for (const provider of windowEthereum.providers) {
          const browserWalletConfig =
            await this.getBrowserWalletConfigForInjectedProviderWithCurrentNetwork(provider);

          if (browserWalletConfig && !walletNames.has(browserWalletConfig.name)) {
            wallets.push(browserWalletConfig);
          }
        }
      } else {
        // there is only one browser provider so push it as the default
        if (this.defaultBrowserWalletConfig) {
          wallets.push(this.defaultBrowserWalletConfig);
          walletNames.add(this.defaultBrowserWalletConfig.name);
        }
      }
    }

    // In case we have multiple browser wallets available, sort them so that MetaMask appears first
    wallets.sort((a, b) => {
      if (a.name === BrowserWallets.MetaMask) {
        return -1;
      } else if (b.name === BrowserWallets.MetaMask) {
        return 1;
      }
      return 0;
    });

    this.browserWallets = wallets;
  }

  /**
   * Returns the configuration for the given InjectedProvider and network
   * unless it cannot be found, in which case it returns undefined.
   */
  getBrowserWalletConfig(
    provider?: InjectedProvider,
    network = 1
    // todo, pass in a name here to get the right one everytime
  ): BrowserWalletConfig | undefined {
    if (!provider || !network) {
      return undefined;
    }

    const name = this.getBrowserWalletName(provider);
    if (!name || this.walletsHidden.has(name.toLowerCase())) {
      return undefined;
    }

    return {
      name,
      network,
      provider: markRaw(provider),
      logo: this.getBrowserWalletLogo(name)
    };
  }

  async getBrowserWalletConfigForInjectedProviderWithCurrentNetwork(
    provider: InjectedProvider
  ): Promise<BrowserWalletConfig | undefined> {
    const net = await new Web3Provider(provider).getNetwork();
    return this.getBrowserWalletConfig(provider, net.chainId);
  }

  /**
   * Returns a typed and human readable wallet name for the InjectedProvider
   */
  getBrowserWalletName(provider: InjectedProvider): BrowserWallets | undefined {
    if (provider.isCoinbaseWallet) {
      return BrowserWallets.CoinbaseWallet;
    } else if (provider.isBraveWallet) {
      return BrowserWallets.Brave;
    } else if (provider.isLedgerConnect) {
      return BrowserWallets.LedgerConnect;
    } else if (provider.isBitski) {
      return BrowserWallets.Bitski;
      // Note - rainbow must be _first_ here. It is both `isMetaMask` and `isRainbow`. If we check for
      // metamask first, we'd mistakenly identify rainbow as metamask.
    } else if (provider.isRainbow) {
      return BrowserWallets.Rainbow;
    } else if (provider.isMetaMask) {
      return BrowserWallets.MetaMask;
    }
    return undefined;
  }

  getBrowserWalletLogo(name: `${BrowserWallets}`): string | undefined {
    if (name) {
      try {
        return require(`@/assets/images/${name.replace(' ', '_').toLowerCase()}.svg`);
      } catch {
        return undefined;
      }
    }
  }

  getBrowserWalletProvider(name: `${BrowserWallets}`): InjectedProvider | undefined {
    // @warn: We refer back to window.ethereum because it is the only thing with
    // the `providers` array consistently defined when there are multiple wallet
    // extensions installed in the browser. EthereumProvider.browserProvider()
    // will return the last provider that was used to call connect() with, which
    // is not the one we want if user rejects that original connect() call and
    // now wants to connect with a diff  provider in the array.
    //
    // @ex: User has MM and CB installed. Rejects a connection to CB. Now wants
    // to connect with MM. EthereumProvider.browserProvider() will return CB,
    // which does not have `providers` defined, where as window.ethereum will.
    const abstractProvider = window.ethereum as InjectedProvider;
    if (!abstractProvider) {
      return undefined;
    }

    if (abstractProvider.providers) {
      let fn;
      if (name === BrowserWallets.MetaMask) {
        fn = (p: InjectedProvider) => !!p.isMetaMask && !p.overrideIsMetaMask;
      } else if (name === BrowserWallets.Bitski) {
        fn = (p: InjectedProvider) => !!p.isBitski;
      } else if (name === BrowserWallets.LedgerConnect) {
        fn = (p: InjectedProvider) => !!p.isLedgerConnect;
      } else if (name === BrowserWallets.Brave) {
        fn = (p: InjectedProvider) => !!p.isBraveWallet;
      } else if (name === BrowserWallets.CoinbaseWallet) {
        fn = (p: InjectedProvider) => !!p.isCoinbaseWallet && !p.isMetaMask;
      } else if (name === BrowserWallets.Rainbow) {
        fn = (p: InjectedProvider) => !!p.isRainbow;
      }

      if (fn) {
        return abstractProvider.providers.find(fn);
      }
    }

    return abstractProvider;
  }

  async created(): Promise<void> {
    const promises: Promise<void>[] = [];
    // Get app details if needed
    if (this.detectApp) promises.push(this.getAppDetails());

    window.manifold = {};
    // Set up the wallets hidden state
    this.multiHidden.split(',').forEach((wallet: string) => {
      this.walletsHidden.add(wallet.trim().toLowerCase());
    });

    // Get address by pulling information from EthereumProvider, because the widget may be created
    // AFTER the event is fired
    const address = EthereumProvider.selectedAddress();

    if (address) {
      // It means we connected before creating this widget
      this.providerAvailable = true;
      localStorage.setItem('connectedAddress', address);
      // Set up the wallet address state
      promises.push(this.onAddressChanged());
      promises.push(this.updateBalance());
    }

    window.addEventListener(ADDRESS_CHANGED, this.onAddressChanged);
    window.addEventListener(REAUTHENTICATE, this.onReauthenticate);
    // We set up the provider and chain listeners afterwards to avoid
    // situations where a provider change can trigger a
    // concurrent auto-reconnect with the above.
    window.addEventListener(PROVIDER_CHANGED, this.onProviderChanged);
    window.addEventListener(CHAIN_CHANGED, this.onChainChanged);

    promises.push(this.updateChainInfo());

    // Wait for all promises to complete
    await Promise.all(promises);
  }

  async getAppDetails(): Promise<void> {
    try {
      this.detectedApp = await getDetails();
    } catch (e) {
      const error = e as { message?: string };
      this.badConfiguration = error.message || 'Config Error';
      throw new Error(this.badConfiguration);
    }
  }

  destroyed(): void {
    window.removeEventListener(PROVIDER_CHANGED, this.onProviderChanged);
    window.removeEventListener(ADDRESS_CHANGED, this.onAddressChanged);
    window.removeEventListener(CHAIN_CHANGED, this.onChainChanged);
  }

  async mounted(): Promise<void> {
    if (!this.network.length && this.fallbackProvider?.length) {
      this.badConfiguration = 'Config Error';
      throw new Error('fallbackProvider should not be configured on network agnostic connections.');
    } else {
      if (
        !!EthereumProvider.network() &&
        this.getSupportedNetworks().length === 1 &&
        this.network[0] !== EthereumProvider.network()
      ) {
        console.warn(
          'An older EthereumProvider was initialized with different inputs, your input for the current connect-widget will be ignored'
        );
      }
      /**
       * When initializing EthereumProvider, we only pass in network if we only want to support a single network.
       */
      const network = this.getSupportedNetworks().length === 1 ? this.network[0] : undefined;
      // Only pass in a mapping of fallbackProvider if it is multiple networks
      const fallbackHost = network ? this.fallbackProvider[0] : this._getRpcMapping();
      if (this.parentFrameUrl) {
        // In iframe, we need to detect app bridge provider first
        let appBridgeProvider;
        console.debug(
          `Parent frame URL configured to '${this.parentFrameUrl}', using app bridge provider`
        );
        try {
          appBridgeProvider = await detectProvider(this.parentFrameUrl);
        } catch (e) {
          console.debug('Error detecting app bridge provider:', e);
        }
        if (appBridgeProvider) {
          await EthereumProvider.initialize({
            network,
            fallbackHost,
            browserProviderOverride: appBridgeProvider,
            browserProviderIgnoreDisconnect: this.browserProviderIgnoreDisconnect
          });
        } else {
          // fallback to regular in-frame provider if app bridge cannot be established
          console.warn('No app bridge provider available');
          await EthereumProvider.initialize({
            network,
            fallbackHost,
            browserProviderIgnoreDisconnect: this.browserProviderIgnoreDisconnect
          });
        }
      } else {
        await EthereumProvider.initialize({
          network,
          fallbackHost,
          browserProviderIgnoreDisconnect: this.browserProviderIgnoreDisconnect
        });
      }
      await this._automaticallyReconnect();
      this._refreshBrowserWalletState();

      // whenever they complete a tx lets update the UX of their eth balance
      window.addEventListener('transactions-confirmed-event', async () => {
        await this.updateBalance();
      });
    }
  }

  /**
   * Handles button triggered connect
   */
  async connectDefaultBrowserWallet(_event: Event): Promise<void> {
    if (this.defaultBrowserWalletConfig) {
      try {
        this.isLoading = true;
        await this._connectWithEthereumProvider(
          false,
          this.getBrowserWalletConfig(
            this.getBrowserWalletProvider(this.defaultBrowserWalletConfig.name)
          )
        );
      } catch (error) {
        // Force disconnect (no need to disconnect provider as it failed to connect)
        this._disconnect(true, true);
      }
    }
  }

  /**
   * Connects to web3 and updates all chain/wallet related info upon success.
   */
  async _connectWithEthereumProvider(
    autoReconnect = false,
    browserWalletOverride?: BrowserWalletConfig
  ): Promise<void> {
    try {
      // @note: connect(undefined) will create a connection to some default browser provider
      await EthereumProvider.connect(browserWalletOverride?.provider);

      if (browserWalletOverride?.name === BrowserWallets.CoinbaseWallet) {
        await this.switchNetwork();
      }
    } catch (error) {
      if (!autoReconnect && this.defaultBrowserWalletConfig) {
        const transactionErrors = web3TransactionErrorHandling(error as TransactionError);
        switch (transactionErrors) {
          case TransactionErrors.REJECTED: {
            // Force disconnect (no need to disconnect provider as it failed to connect)
            this._disconnect(true, true);
            break;
          }
          case TransactionErrors.LEDGER_ERROR: {
            // Force disconnect (no need to disconnect provider as it failed to connect)
            this._disconnect(true, true);
            break;
          }
          case TransactionErrors.PENDING: {
            alert(`Please open ${this.defaultBrowserWalletConfig.name} Wallet to continue.`);
            break;
          }
          default: {
            alert(
              `Could not connect to ${this.defaultBrowserWalletConfig.name}, please try refreshing your page. If you continue to have issues, try closing your browser and re-opening it.`
            );
            break;
          }
        }

        throw error;
      }
    }
  }

  /**
   * Disconnects from web3 and deletes our Oauth cookie for the JSON API.
   */
  disconnectWallet(): void {
    this._disconnect();
  }

  /**
   * Disconnects from web3
   *
   * @param skipProviderDisconnect  - Do not disconnect the provider.
   * @param force                   - Force disconnect and reset even if there is no connected wallet
   */
  _disconnect(skipProviderDisconnect = false, force = false): void {
    this._disconnectBase(skipProviderDisconnect, force);
  }

  /**
   * This function should be called by any mixin that overrides _disconnect
   *
   * @param skipProviderDisconnect  - Do not disconnect the provider.
   * @param force                   - Force disconnect and reset even if there is no connected wallet
   */
  _disconnectBase(skipProviderDisconnect = false, force = true): void {
    if (this.walletConnected || force) {
      localStorage.removeItem('connectedAddress');
      this.walletAddressFull = undefined;
      this.walletAddressShort = undefined;
      this.walletENS = undefined;
      this.walletBalance = undefined;
      this.walletConnected = false;
      window.manifold = {
        isAuthenticated: false,
        address: '',
        dataClient: undefined,
        oauthToken: undefined
      };
      this.isLoading = false;
      if (!skipProviderDisconnect) {
        EthereumProvider.disconnect();
      }
    }
  }

  /**
   * Connects to web3 only if this.automaticallyReconnect is set and we
   * are already connected with a valid wallet address.
   */
  async _automaticallyReconnect(): Promise<void> {
    // Reconnect if
    // 1. autoReconnect is set
    // 2. we have a connected address in local storage
    // 3. we have a provider (only available if no network or network is correct
    if (
      this.autoReconnect &&
      localStorage.getItem('connectedAddress') &&
      EthereumProvider.provider()
    ) {
      // Provider only available if no network or network is correct
      if (!EthereumProvider.selectedAddress()) {
        // No address and address listener setup
        // Run auto-reconnect, which will trigger the address
        // changed callback and set up the appropriate state
        await this._connectWithEthereumProvider(true);
      }
    }
  }

  /**
   * Updates the current eth balance of the wallet being displayed.
   * Call this whenever the adddress, chain, or provider is changed.
   */
  async updateBalance(): Promise<void> {
    const provider = EthereumProvider.provider();
    try {
      if (this.walletAddressFull && provider) {
        const balanceString = (await provider.getBalance(this.walletAddressFull)).toString();
        const ethValue = formatEther(balanceString);
        const with3Decimals = ethValue.match(/^-?\d+(?:\.\d{0,3})?/);
        // with3Decimals looks like this (does not round!): [ '0.017', index: 0, input: '0.017926361227063654', groups: undefined ]
        if (with3Decimals && with3Decimals.length > 0) {
          this.walletBalance = with3Decimals[0];
        } else {
          this.walletBalance = undefined;
        }
      } else {
        this.walletBalance = undefined;
      }
    } catch (e) {
      // Error getting wallet balance
      console.warn(`Error getting wallet balance`, e);
    }
  }

  /**
   * Updates the name and corresponding color for chainInfo. Call this
   * whenever the chain information may have updated.
   */
  async updateChainInfo(): Promise<void> {
    /**
     * @NOTE possibility that there is duplicate functionality here
     * between this method and badConfiguration variable.
     */
    const chainId: number | undefined = EthereumProvider.chainId();
    if (this.network.length) {
      this.wrongChain =
        !chainId ||
        !this.getSupportedNetworks()
          .map((network) => network.valueOf())
          .includes(chainId);
    }
    if (chainId) {
      this.chainInfo = {
        // @TODO: this type from the SDK is a little odd...
        name: EthereumNetwork[chainId] as keyof typeof EthereumNetwork,
        color: ETHEREUM_NETWORK_COLORS[chainId]
      };
      this.providerAvailable = true;
    } else {
      // No network provider or valid provider for specified network.
      this.chainInfo = null;
      this.providerAvailable = false;
    }
  }

  /**
   * Fires when reauthenication is requested again
   */
  async onReauthenticate(): Promise<void> {
    await this._authenticate(true);
  }

  /**
   * Fires when the address is changed
   */
  async onAddressChanged(): Promise<void> {
    await this._authenticate();
  }

  /**
   * Authentication helper
   *
   * First it updates all chainInfo.
   * Then it updates everything related to the wallet adddress and ens name
   * if possible. Finally it stores the wallet address as "connectAddress"
   * inside of local storage. If ther ewas an issue or the address is now
   * undefined/null, we clear all wallet related vlaues and clear localStorage
   * of the "connectedAddress" itme.
   */
  async _authenticate(force = false): Promise<void> {
    this.badConfiguration = null;
    this.updateChainInfo();
    const address = EthereumProvider.selectedAddress();
    const ens = EthereumProvider.selectedENSName();
    if (force || address !== this.walletAddressFull || ens !== this.walletENS) {
      // Reset current state via disconnect (no need to disconnect the provider or force disconnect)
      this._disconnect(true);

      this.walletAddressFull = address;
      this.walletENS = ens;
      if (address) {
        try {
          const addressLength = address.length;
          const newShortenedAddress =
            address.slice(0, 6) + '...' + address.slice(addressLength - 4, addressLength);
          this.walletAddressShort = newShortenedAddress;
          this.walletConnected = true;
          localStorage.setItem('connectedAddress', address);
        } catch (error) {
          const transactionErrors = web3TransactionErrorHandling(error as TransactionError);
          switch (transactionErrors) {
            case TransactionErrors.REJECTED: {
              // Force disconnect (no need to disconnect provider)
              this._disconnect(true, true);
              break;
            }
            case TransactionErrors.LEDGER_ERROR: {
              // Force disconnect (no need to disconnect provider)
              this._disconnect(true, true);
              break;
            }
            case TransactionErrors.PENDING: {
              alert(`Please open your wallet and sign in.`);
              break;
            }
            default: {
              alert('There was an issue with that wallet connection');
              break;
            }
          }
        }
      } else {
        // No address, no state
        this._disconnect(true);
      }
      this.isLoading = false;
    }
  }

  /**
   * Fires when the chain is changed
   * Ensures the chainInfo is updated then updates the balance we see.
   */
  async onChainChanged(): Promise<void> {
    this.badConfiguration = null;

    // There is a case where the auto-reconnect didn't work on initialization
    // because the provider was not available yet.  This will cause a chain change
    // event once it becomes available, so try auto-connection when this happens
    await this._automaticallyReconnect();
    this._refreshBrowserWalletState();
    await this.updateBalance();
  }

  /**
   * Fires when the provider is changed
   * Ensures that the chainInfo is updated then automatically reconnnects.
   */
  async onProviderChanged(): Promise<void> {
    this.badConfiguration = null;
    this._refreshBrowserWalletState();
    await this._automaticallyReconnect();
  }

  _refreshBrowserWalletState(): void {
    this.updateChainInfo();
    this.computeAllBrowserWalletConfigs();
  }

  /*
   * Helpful in every view for when users need to install a wallet
   */
  openMetamaskLink(): void {
    window.open('https://metamask.io', '_blank');
  }

  /**
   * Switches wallet to the default chain
   */
  async switchNetwork(): Promise<void> {
    await EthereumProvider.switchToCorrectChain();
  }

  /**
   * Maps each network to a Provider URI for usage as an RPC endpoint
   */
  _getRpcMapping(): Record<EthereumNetwork, string> | undefined {
    if (!this.network.length) {
      return;
    }
    const chains = this.getSupportedNetworks();
    const providerURIs = this._getProviderURIs();
    return chains.reduce((acc, chain, currentIndex) => {
      acc[chain] = providerURIs[currentIndex];
      return acc;
    }, {} as Record<EthereumNetwork, string>);
  }

  _getProviderURIs(): string[] {
    if (!this.fallbackProvider.length) {
      return [];
    }
    return this.fallbackProvider.map((providerURI) => this._processURI(providerURI));
  }

  _processURI(uri: string): string {
    const infuraMatch = uri.match(/^(wss:\/\/)(.*\.infura.io)(\/ws)(\/v[0-9]+\/[0-9a-f]+)$/);
    if (infuraMatch) {
      return `https://${infuraMatch[2]}${infuraMatch[4]}`;
    }
    return uri.replace('wss://', 'https://').replace('ws://', 'http://');
  }

  getSupportedNetworks(): EthereumNetwork[] {
    return [...this.network, ...this.optionalNetwork];
  }
}
